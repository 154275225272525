import * as React from "react";
import Layout from "../../components/layout";

// styles

// data

// markup
const TermsPage = () => {
  return (
    <Layout
      title="利用規約 | CO Record &amp; journal"
      policyStyle={true}
      showHeader={true}
      isTop={false}
    >
      <main id="id-main" className="policy-body">
        <section>
          <div className="contents">
            <div className="text-box">
              <br />
              <br />
              <br />

              <h3>CO Record &amp; Journal 利用規約</h3>
              <p>
                CO Record &#x26; Journal
                利用規約（以下「本規約」といいます。）は、帝人株式会社（以下「当社」といいます。）が提供する
                「CO Record &#x26;
                Journal」の利用に関して、お客様に同意していただく必要のある事柄を記載しています。お客様が本サービスをご利用になる際には、本規約が適用されます。本規約に同意しない場合、本サービスをご利用頂けませんので、ご利用の前に必ずお読みください。
              </p>
              <p>
                第１条（定義）
                <br />
                本規約において、次の各号に掲げる用語の意義は、当該各号に定めるところによるものとします。
              </p>
              <p>
                （１）「本アプリ」
                <br />
                当社が提供する「CO Record &#x26;
                Journal」という名称のアプリケーション（理由の如何を問わずその名称または内容が変更された場合の当該変更後のアプリケーションを含みます。）及び関連ソフトウェアをいいます。
              </p>
              <p>
                （２）「本サービス」
                <br />
                本アプリ及びこれに関連するアプリをインストールすることにより又はこれに関連してユーザーが利用することができるすべてのサービス及びこれらサービスと連携させて利用可能なＷｅｂサービスをいいます。
              </p>
              <p>
                （３）「サービス利用契約」
                <br />
                ユーザーが本サービスを利用するに際し、ユーザーと当社との間に発生する本サービスの利用に関する契約をいい、本規約、「CO
                Record &#x26; Journal
                プライバシーポリシー」及び本サービスに関して当社が配布、配信又は掲示する文書（以下、本サービスに関して当社が配布、配信又は掲示する文書を「個別利用規約」といいます）等を含みます。
              </p>
              <p>
                （４）「ユーザー」　
                <br />
                当社とサービス利用契約の内容に承認の上で本サービスを利用する個人をいいます。
              </p>
              <p>
                （５）「ユーザー情報」　
                <br />
                ユーザーが本サービスの利用に際して登録した情報、本サービス利用中に当社が必要と判断して登録を求めた情報及びこれらの情報についてユーザー自身が追加、変更を行った場合の当該情報をいいます。
              </p>
              <p>
                （６）「端末情報等」
                <br />
                本サービスの利用に伴い当社が取得するユーザーの携帯端末情報、位置情報及びセンサー情報をいいます。
              </p>
              <p>
                （７）「コンテンツ」　
                <br />
                本サービスを通じて当社からユーザーに提供される情報（文章、画像、動画、音声、音楽その他のサウンド、イメージ、ソフトウェア、プログラム、コードその他のデータを含みますが、これらに限られません。）をいいます。
              </p>
              <p>
                （８）「ユーザーコンテンツ」　
                <br />
                ユーザーが本サービスを利用して投稿その他の方法により送信するコンテンツをいいます。
              </p>
              <p>
                （９）「第三者コンテンツ」
                <br />
                本サービスの提供に際し、これに付随するサービスとして、当社及びユーザー以外の第三者によって提供されるコンテンツをいいます。
              </p>
              <p>
                （１０）「アカウント」　
                <br />
                パスワードと組み合わせて、ユーザーとその他の者とを識別するために用いられる符号をいいます。
              </p>
              <p>
                （１１）「パスワード」　
                <br />
                アカウントと組み合わせて、ユーザーとその他の者とを識別するために用いられる符号をいいます。
              </p>
              <p>
                （１２）「当社ウェブサイト」　
                <br />
                そのドメインが「dive-co.net」または「dive-co.jp」であって、当社が運営する本アプリ及び本サービスに関するウェブサイト（理由の如何を問わず、当社のウェブサイトのドメイン又は内容が変更された場合は、当該変更後のドメインを含みます。）をいいます。
              </p>
              <p>
                （１３）「提携パートナー」
                <br />
                当社との間で締結する契約に基づき、本サービスと提携するサービス（以下「提携サービス」といいます。）を提供し又はその運営を行う者をいう。
              </p>
              <p>
                （１４）「知的財産権」
                <br />
                著作権、特許権、実用新案権、商標権、意匠権その他の知的財産権（それらの権利を取得し、又はそれらの権利につき登録等を出願する権利を含みます。）をいいます。
              </p>
              <p>
                第２条（総則・適用範囲）
                <br />
                １．本規約は、当社が提供・運営する本サービスの利用に関する基本的な事項を規定します。
                <br />
                ２．本規約は、本サービスの利用に関し、当社及びユーザーに対して適用されます。
                <br />
                ３．当社が、当社ウェブサイト、当社アプリ利用画面上に本サービスに関する個別規定や追加規定を掲載する場合、又は電子メール等により本サービスに関するルール等を発信する場合、それらは本規約の一部を構成するものとし、個別規定、追加規定又はルール等が本規約と抵触する場合には、当該個別規定、追加規定又はルール等が優先されるものとします。
                <br />
                ４．当社ウェブサイト、本アプリ又は本サービスの提供に際してリンクされた他のウェブサイト、アプリケーションその他のサービス（以下、本アプリ又は本サービスの提供に際してリンクされた他のウェブサイト、アプリケーションその他のサービスを総称して「外部サービス等」といいます。）については、当社ウェブサイト、外部サービス等に定められる利用規約等に従ってご利用ください。
              </p>
              <p>
                第３条（ユーザー情報の登録）
                <br />
                １．ユーザーは、本サービスの利用にあたり必要な情報及び当社が必要と判断した情報を登録するものとします。
                <br />
                ２．ユーザーは、ユーザー情報の登録にあたっては、真実かつ正確な情報を提供しなければなりません。当社は、ユーザー自身が登録したユーザー情報を前提として、本サービスを提供いたします。ユーザー情報の内容に虚偽、誤り又は記載漏れがあったことによりユーザーに生じた損害について、当社は一切責任を負いません。
                <br />
                ３．ユーザーは、ユーザー情報に変更があった場合、変更から１４日以内に、当社の定める方法により、当該変更事項を当社に通知しなければなりません。その際、当社から資料の提出の要求があった場合には、当該要求された資料を提出するものとします。
                <br />
                ４．前項の通知及び資料の提出を怠ったことにより当社からの通知が不到達となった場合、当該通知は通常到達すべき時に到達したとみなされるものとします。
              </p>
              <p>
                第４条（アカウント登録及び有料サービス）
                <br />
                １．本サービスの利用希望者は、サービス利用契約に同意し、前条に基づき当社の要求するユーザー情報を全て登録することにより本サービスの利用を申し込むものとします。当社は、当該申し込みを承諾した場合、本サービスのアカウントを発行し、本サービスの利用希望者に対してユーザーとしての資格を付与します。ユーザー資格を付与後、一部のサービスを除き、ユーザーは直ちに本サービスの利用を開始できます。
                <br />
                ２．当社は、本サービスの利用を希望するユーザーに次の各号に規定するいずれかの事由があると判断した場合、本サービスの利用の申請を承認しないことがあり、その理由については一切の開示義務を負わないものとします。
                <br />
                （１）アカウント登録の申請に際して虚偽若しくは不正確な点又は記載漏れが認められた場合
                <br />
                （２）過去にサービス利用契約に違反したことがある者からの申請である場合
                <br />
                （３）サービス利用契約に同意しない場合
                <br />
                （４）本サービス又は当社が提供するその他のサービスの利用に際して、過去にアカウントの削除等の利用停止措置を受けたことがあり、又は現在受けている場合
                <br />
                （５）民法に規定する制限行為能力者に該当し、かつ、法定代理人の同意等を得ていない場合
                <br />
                （６）第８条（禁止行為）に該当する場合、又はそのおそれがあると当社が判断した場合
                <br />
                （７）料金の支払能力に疑義がある場合
                <br />
                （８）日本国外に居住・所在している場合
                <br />
                ２．本サービスの中には、無料で利用できるサービス（以下「無料サービス」といいます。）とユーザーが別途定める利用料金を当社に支払わなければ利用できないサービス（以下「、有料サービス」といいます。）があります。有料サービスの利用料金の金額、決済方法は、こちら&#x3C;特定商取引法に基づく表記へのリンクをご確認ください。
                <br />
                ３．当社は、有料サービス又は無料サービスについて、料金の変更をする場合があります。（この変更には無料サービスの有料化を含みます。）当社が料金を変更する場合、原則として変更の７日前までに、第
                19
                条第１項所定の方法その他当社が適当と認める方法で変更内容をユーザーに通知します。変更日までにユーザーが変更にかかるサービスの利用を中止しない場合、ユーザーは変更に同意したものとみなします。
                <br />
                ４．ユーザーは、料金の支払を遅滞した場合、当社に対し、年１４．６％の割合による遅延損害金を支払うものとします。
              </p>
              <p>
                第５条（アカウント及びパスワードの管理）
                <br />
                １．ユーザーは、自己の責任において自身のユーザー情報、アカウント及びパスワード等を管理及び保管するものとし、これを第三者に利用させ、又は貸与、譲渡、名義変更、売買、担保設定その他の処分をしてはならないものとします。当社は、アカウント及びパスワードの一致を確認した場合、当該アカウント及びパスワードを保有するものとして登録されたユーザーが本サービスを利用したものとみなします。
                <br />
                ２．自身のユーザー情報、アカウント及びパスワードの管理不十分、使用上の過誤、第三者の使用等による損害の責任はユーザーが負うものとし、当社は一切の責任を負いません。
                <br />
                ３．ユーザーは、自身のユーザー情報、アカウント又はパスワードが盗用され又は第三者に使用されていることが判明した場合には、直ちにその旨を当社に通知するとともに、当社からの指示に従うものとします。
                <br />
                ４．本条に違反することにより発生したいかなる種類の損失又は損害についても、ユーザーが自ら責任を負うものとし、これらの損失又は損害について、当社は、直接的か間接的かを問わず、一切の責任を負わないものとします。
              </p>
              <p>
                第６条（本サービスの利用）
                <br />
                １．本アプリのダウンロードを完了した上で、サービス利用契約を承諾することで、ユーザーは本サービス利用することができるようになります。未成年者は、親権者等の法定代理人の同意を得た上で本アプリをダウンロードし、法定代理人の同意のもと本サービスを利用しなければならないものとします。
                <br />
                ２．ユーザーは、本規約に定める目的の範囲内でかつ本規約に違反しない範囲内で、当社の定める方法に従い、本サービスを利用することができます。
                <br />
                ３．本アプリは、ユーザーが私的に使用する目的でのみ利用することができ、販売、配布又は開発等の私的使用以外の目的で使用してはならないものとします。
                <br />
                ４．ユーザーは、本アプリを、当社が提供する状態でのみ利用するものとし、本アプリの複製、修正、変更、改変又は翻案を行ってはならないものとします。
                <br />
                ５．本サービスの提供を受けるために必要なコンピューター、ソフトウェアその他の機器、通信回線その他の通信環境等の準備及び維持は、ユーザーの費用と責任において行うものとします。当社は、ユーザーが用意した通信環境等に起因してユーザーに発生した損害について、一切の責任を負わないものとします。
                <br />
                ６．本サービスの全部又は一部について、年齢、本人確認の有無、ユーザー情報の有無、アカウント登録の有無、課金の有無、その他、当社が必要と判断する条件を満たしたユーザーに限り利用できる場合があるものとし、ユーザーはこれに同意するものとします。
              </p>
              <p>
                第７条（提携パートナーによるサービス等の提供）
                <br />
                １．本サービスの利用にあたり、ユーザーが、提携パートナーにより提供される提携サービス等を利用する場合には、本規約及び個別利用規約のほか、当該提携パートナーの定める利用規約その他の条件に従うものとします。
                <br />
                ２．当社は、提携パートナーにより提供される提携サービスの内容がユーザーの特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、ユーザーによる提携サービスの利用がユーザーに適用のある法令又は業界団体の内部規則等に適合すること、及び不具合が生じないことについて、何ら保証するものではありません。
                <br />
                ３．ユーザーは、提携パートナーにより提供される提携サービスの内容若しくは運用等の変更により、又はユーザーによる提携サービスの利用制限等により、本サービスを利用できなくなる場合があることを予め了承するものとします。当社は、提携サービスに起因してユーザーに発生した損害について、一切の責任を負わないものとします
                。
              </p>
              <p>
                第８条（禁止行為）
                <br />
                ユーザーは、本サービス（提携サービスを含みます。以下本条において同様です。）の利用にあたり、自ら又は第三者をして以下の各号のいずれかに該当する行為をしてはなりません。
                <br />
                （１）法令、裁判所の判決、決定若しくは命令、又は法令上拘束力のある行政措置に違反する行為及びこれらを助長する行為又はそのおそれのある行為
                <br />
                （２）当社又は他のユーザーその他の第三者に対する詐欺又は脅迫行為
                <br />
                （３）公序良俗に反し又は善良な風俗を害するおそれのある行為
                <br />
                （４）当社又は他のユーザーその他の第三者の知的財産権、肖像権、プライバシー、名誉、その他の権利又は利益を侵害する行為又はそのおそれのある行為
                <br />
                （５）本サービスを通じ、以下に該当し又は該当すると当社が判断した情報を当社又は他のユーザーその他の第三者に送信する行為
                <br />
                ・過度に暴力的又は残虐な表現を含む情報
                <br />
                ・コンピューター・ウィルスその他の有害なプログラムを含む情報
                <br />
                ・過度にわいせつな表現を含む情報
                <br />
                ・差別を助長する表現を含む情報
                <br />
                ・自殺、自傷行為を助長する表現を含む情報
                <br />
                ・薬物の不適切な利用を助長する表現を含む情報
                <br />
                ・反社会的な表現を含む情報
                <br />
                ・ジャンクメール、スパムメール、チェーンメール等の第三者への拡散を求める情報
                <br />
                ・違法な勧誘、宣伝等を含む情報
                <br />
                ・他人に不快感を与える表現を含む情報
                <br />
                ・面識のない異性との出会いを目的とした情報
                <br />
                ・上記に類する情報
                <br />
                （６）本アプリ及び本サービスで提供されるアプリケーション、ソフトウェアの逆コンパイル、逆アセンブル等のリバースエンジニアリング行為
                <br />
                （７）本サービスのネットワーク又はシステム等に過度な負荷をかける行為
                <br />
                （８）本サービスに接続しているシステム全般に権限なく不正にアクセスし又は当社設備に蓄積された情報を不正に書き換え若しくは消去する行為その他当社に損害を与える行為
                <br />
                （９）故意に虚偽のデータ等を公開し又は投稿する行為
                <br />
                （１０）他のユーザーの情報の収集を目的とする行為
                <br />
                （１１）当社又は他のユーザーその他の第三者に成りすます行為
                <br />
                （１２）他のユーザーのアカウント又はパスワードを利用する行為
                <br />
                （１３）当社が事前に許諾しない本サービス上での宣伝、広告、勧誘、又は営業行為
                <br />
                （１４）面識のない異性との出会いを目的とした行為
                <br />
                （１５）本アプリ又は本サービスを利用した営業活動並びに営利を目的とした利用及びその準備を目的とした利用行為（ただし、当社が別途承認した場合には、この限りではありません。）
                <br />
                （１６）本アプリ及び本サービスの提供を困難にすることその他その提供及び運営に支障を来す行為又はそのおそれがある行為
                <br />
                （１７）当社又は本サービスの営業活動を妨害する行為又はそのおそれがある行為
                <br />
                （１８）サービス利用契約及び本サービスの趣旨・目的に反する行為
                <br />
                （１９）前各号の行為を直接又は間接に惹起し、又は容易にする行為
                <br />
                （２０）その他、当社が不適切と判断する行為
                <br />
                ２．当社は、ユーザーによる前項の規定への違反によって当社に生じた損害又は損失について、ユーザー及びユーザーであった者に対して請求することができ、当該ユーザー及びユーザーであった者は、当社に対して当該請求に係る金額を直ちに支払うものとします。
              </p>
              <p>
                第９条（ユーザーによる本サービスの利用終了）
                <br />
                １．ユーザーは、当社所定の方法により、いつでも本サービスの利用を終了することができます。本サービスの利用を終了したユーザーは、当該終了時点から本サービスを利用することができなくなります。ユーザーは、誤ってアカウントを削除した場合であっても、アカウント、ユーザー情報、端末情報等、その他本サービスに蓄積した情報の復旧はできないことにつき予め承諾するものとします。
                <br />
                ２．ユーザーは、アカウント登録の抹消又は有料サービスの利用終了を希望する場合、当社所定の方法により手続きをおこなうものとします。アカウント登録の抹消又は有料サービスの利用終了の手続きを行なった場合、手続き完了の時点から、当該アカウント登録又は課金に基づき利用が可能であった本サービスについて、利用できなくなります。また、アカウント登録の抹消又は有料サービスの一部のみの利用終了手続きを行っても、本サービスの利用は終了されません。本サービス全体の利用終了を希望する場合、当社所定の本サービス利用終了手続きをとってください。
                <br />
                ３．ユーザーは、本サービス利用終了後も、当社及びその他の第三者に対するサービス利用契約上の一切の義務及び債務（損害賠償を含みますが、これに限りません。）を免れるものではありません。
                <br />
                ４．当社は、ユーザーが本サービスの利用を終了した後も、当該ユーザーが当社に提供した情報を保有・利用することができるものとします。
                <br />
                ５．ユーザーが本サービスの利用を終了した場合、当社は、当社の裁量により、あらかじめ通知することなく当該ユーザーのアカウントを削除することができるものとします。
                <br />
                ６．当社は、当社の裁量により、あらかじめ通知することなく、最終のアクセスから１年間以上経過しているアカウントを削除することができるものとします。
                <br />
                ７．本サービス利用終了後、再度本サービスの利用を希望する際は、再度本アプリのダウンロードを行う必要があります。ユーザーは、再ダウンロードの際に前のデータが引き継がれないことを予め承諾するものとします。
              </p>
              <p>
                第 10 条（規約違反の場合の措置等）
                <br />
                １．当社は、民法（民法の一部を改正する法律（平成２９年法律第４４号）により改正された後のものに限り、以下本条において同様とします。）第５４２条各号に定めるもののほか、ユーザーが次の各号の一に該当し又は該当するおそれがあると当社が判断した場合には、当社の裁量により、当該ユーザーのユーザー情報、端末情報等、ユーザーコンテンツの削除、本サービスの利用の一時停止若しくは制限、又はアカウントの削除（サービス利用契約の解除）（以下「利用停止等」といいます。）をすることができるものとします。なお、本項による利用停止等は、民法第５４２条各号に定めるもの及び次の各号に該当するものにつき、当社の責めに帰すべき事由がある場合にも、その行使及び効力を妨げられないものとし、本規約においては民法第５４３条を適用しないものとします。
                <br />
                （１）サービス利用契約のいずれかの条項に違反した場合
                <br />
                （２）当社に提供されたユーザー情報の全部又は一部につき虚偽の事実があることが判明した場合
                <br />
                （３）当社が指定する決済方法につき不正が判明した場合、又は当社が指定する決済サービス会社からユーザーの決済を停止又は無効扱いとされた場合
                <br />
                （４）支払停止若しくは支払不能となり、又は破産手続開始、民事再生手続開始若しくはこれらに類する手続の開始の申立てがあった場合
                <br />
                （５）ユーザーが死亡し又は後見開始、保佐開始若しくは補助開始の審判を受けた場合
                <br />
                （６）当社からの問合せその他の回答を求める連絡に対して３０日間以上応答がない場合
                <br />
                （７）本サービスの利用に際して、過去に本サービス利用停止又はアカウント削除等の措置を受けたことがあり又は現在受けている場合
                <br />
                （８）未成年者、成年被後見人、被保佐人又は被補助人のいずれかであって、法定代理人、後見人､保佐人又は補助人の同意等を得ていなかった場合
                <br />
                （９）本サービスの運営・保守管理上必要であると当社が判断した場合
                <br />
                （１０）その他前各号に類する事由があると当社が判断した場合
                <br />
                ２．ユーザーは、利用停止等の後も、当社及びその他の第三者に対するサービス利用契約上の一切の義務及び債務（損害賠償債務を含みますが、これに限りません。）を免れるものではありません。
                <br />
                ３．当社は、本条に基づき当社が行った行為によりユーザーに生じた損害について一切の責任を負わず、利用停止等の後も、当該ユーザーが当社に提供した情報を保有・利用することができるものとします。
              </p>
              <p>
                第 11 条（本サービスの変更、追加、廃止及び中断等）
                <br />
                １．当社は、ユーザーに事前の通知をすることなく、本サービスの内容の全部又は一部を変更又は追加することができるものとします。
                <br />
                ２．当社は、当社の判断により本サービスの全部又は一部の提供・運営を終了することができるものとします。当社は、当社の判断により本サービスの全部又は一部の提供・運営を終了する場合、当社が適当と判断する方法でユーザーにその旨通知いたします。ただし、緊急の場合はユーザーへの通知を行わない場合があります。３．当社は、以下各号の事由が生じた場合には、ユーザーに事前に通知することなく、本サービスの一部又は全部を一時的に中断することができるものとします。
                <br />
                （１）本サービス用のハード・ソフト・通信機器設備等に関わるメンテナンスや修理を定期的又は緊急に行う場合
                <br />
                （２）アクセス過多、その他予期せぬ要因でシステムに負荷が集中した場合
                <br />
                （３）ユーザーのセキュリティを確保する必要が生じた場合
                <br />
                （４）電気通信事業者の役務が提供されない場合
                <br />
                （５）地震、落雷、火災、停電、その他の不慮の事故又は戦争、紛争、動乱、暴動、労働争議等の不可抗力により本サービスの提供が困難な場合
                <br />
                （６）停電、サーバー故障等の発生又は本サービスに関する設備・機器の不具合が発生した場合
                <br />
                （７）法令又はこれらに基づく措置により本サービスの運営が不能となった場合
                <br />
                （８）その他前各号に準じ当社が必要と判断した場合
                <br />
                ４．当社は、本条に基づき当社が行った措置によりユーザーに生じた損害について一切の責任を負いません。
              </p>
              <p>
                第 12 条（権利の帰属）
                <br />
                １．本アプリ及び本サービスにおいて当社が提供するコンテンツ又は第三者コンテンツに関する一切の知的財産権は、当社又は当社にライセンスを許諾している者に帰属し、本規約に基づく本サービスのいかなる使用許諾も、当社又は当社にライセンスを許諾している者からユーザーに対する知的財産権の使用許諾を意味するものではありません。
                <br />
                ２．ユーザーは、当社の許諾を得ることなく、当社が提供するコンテンツ又は第三者コンテンツの翻案、編集及び改変等を行い、これを第三者に使用させ又は公開することはできず、いかなる理由によっても当社又は当社にライセンスを許諾している者の知的財産権を侵害するおそれのある行為（逆アセンブル、逆コンパイル、リバースエンジニアリングを含みますが、これに限定されません。）をしてはなりません。
                <br />
                ３．本サービス上、商標、ロゴ及びサービスマーク等（以下総称して「商標等」といいます。）が表示される場合がありますが、当社は、ユーザーその他の第三者に対し何ら商標等を譲渡し、又はその使用を許諾するものではありません。
                <br />
                ４．ユーザーは、ユーザーコンテンツについて、自らが投稿その他の方法によりコンテンツを送信することについての適法な権利を有していること、及びユーザーコンテンツが第三者の権利を侵害していないことについて、当社に対し表明し、保証するものとします。
                <br />
                ５．ユーザーは、ユーザーコンテンツについて、当社に対し、世界的、非独占的、無償、サブライセンス可能かつ譲渡可能な使用、複製、配布、派生著作物の作成、表示及び実行に関するライセンスを付与します。かかる当社のライセンスは、当該ユーザーによる本サービスの利用が停止し又は利用停止等された後も、有効に存続するものとします。
                <br />
                ６．ユーザーは、ユーザーコンテンツについて、当社及び当社から権利を承継し又は許諾された者に対して著作者人格権を行使しないことに同意するものとします。
                <br />
                ７．ユーザーは、自己の責任においてユーザーコンテンツのバックアップを行い、当社はユーザーコンテンツのバックアップを行う義務を負わないものとします。
                <br />
                ８．当社は、ユーザーコンテンツに関し、法令若しくは本規約に違反し又は違反するおそれがあると認めた場合、あらかじめユーザーに通知することなく、ユーザーコンテンツの削除その他の方法により、本サービスの利用を制限することができるものとします。
              </p>
              <p>
                第 13 条（反社会的勢力排除）
                <br />
                １．ユーザーは、自らが暴力団、暴力団員、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標榜ゴロ又は特殊知能暴力集団等、その他これらに準ずる者(これらに該当する者でなくなった日から
                5
                年を経過しない者を含み、以下これらを「反社会的勢力」といいます。）に該当しないこと、及び次の各号のいずれにも該当しないことを表明し、かつ将来にわたっても該当しないことを確約します。
                <br />
                （１）反社会的勢力がユーザーの所属する法人、団体、組織等（以下「所属組織等」といいます。）の経営を支配していると認められる関係を有すること
                <br />
                （２）反社会的勢力が所属組織等の経営に実質的に関与していると認められる関係を有すること
                <br />
                （３）自己、所属組織等若しくは第三者の不正の利益を図る目的又は第三者に損害を加える目的をもってするなど、不当に反社会的勢力を利用していると認められる関係を有すること
                <br />
                （４）反社会的勢力に資金等を提供し、又は便宜を供与するなどの関与をしていると認められる関係を有すること
                <br />
                （５）所属組織等の役員又は経営に実質的に関与している者が反社会的勢力と社会的に非難されるべき関係を有すること
                <br />
                ２．ユーザーは、自ら又は第三者を利用して次の各号のいずれかに該当する行為を行わないことを確約します。
                <br />
                （１）暴力的な要求行為
                <br />
                （２）法的な責任を超えた不当な要求行為
                <br />
                （３）取引に関して、脅迫的な言辞又は暴力を用いる行為
                <br />
                （４）風説を流布し、偽計若しくは威力を用いて相手方の信用を毀損し、又は業務を妨害する行為
                <br />
                第 14 条（秘密保持）
                <br />
                １．本規約において「秘密情報」とは、本サービスに関連して、ユーザーが、当社より書面、口頭若しくは記録媒体等により提供若しくは開示されたか、又は知り得た、当社の技術、営業、業務、財務、組織、その他の事項に関する全ての情報を意味します。ただし、以下の各号に該当するものは、秘密情報に該当しないものとします。　
                <br />
                （１）当社から提供若しくは開示がなされ又は知得する前に既に適法に保有していたもの
                <br />
                （２）当社から提供若しくは開示がなされ又は知得した時に公知であったもの
                <br />
                （３）当社からの提供若しくは開示又は知得の前後を問わず、自己の責めに帰せざる事由により公知となったもの
                <br />
                （４）秘密保持義務を負うことなく正当な権原を有する第三者から適法に取得したもの
                <br />
                （５）秘密情報によることなく単独で開発したもの
                <br />
                ２．ユーザーは、秘密情報を本サービスの利用目的以外の目的で使用してはならず、当社の事前の書面による承諾なしに、第三者に対し秘密情報を提供、開示又は漏洩してはならないものとします。
                <br />
                ３．前項の定めにかかわらず、ユーザーは、法令、裁判所又は政府機関の命令、要求又は要請に基づき、秘密情報を開示することができます。ただし、当該命令、要求又は要請があった場合、速やかにその旨を当社に通知しなければなりません。
                <br />
                ４．ユーザーは、秘密情報を複製、複写、翻案等する場合には、事前に当社の書面による承諾を得るものとし、複製物等の管理については第２項に準じて厳重に行うものとします。
                <br />
                ５．ユーザーは、当社から求められた場合はいつでも、遅滞なく、当社の指示に従い、秘密情報及び秘密情報を記載又は包含した書面その他の記録媒体物並びにその全ての複製物等を返却又は廃棄しなければなりません。
              </p>
              <p>
                第 15 条（損害賠償）
                <br />
                １．ユーザーは、本規約に違反することにより、又は本サービスの利用に関連して当社に損害を与えた場合、当社に対しその全ての損害（弁護士等専門家費用及び当社人件費相当額を含みます。）を賠償しなければなりません。
                <br />
                ２．ユーザーによる本サービスの利用に関連して、当社が、他のユーザーその他の第三者から権利侵害その他の理由により何らかの請求を受けた場合、当該ユーザーは、当該請求に基づき当社が当該第三者に支払いを余儀なくされた金額及び当該請求に係る紛争等の解決のために当社が負担した金額（弁護士等専門家費用及び当社人件費相当額を含みます。）を賠償しなければなりません。
                <br />
                ３．当社は、本サービスに関連してユーザーが被った損害について、一切賠償の責任を負いません。なお、消費者契約法の適用その他の理由により、本項その他当社の損害賠償責任を免責する規定にかかわらず、当社がユーザーに対して損害賠償責任を負う場合においても、当社の賠償責任の範囲は、当社の責に帰すべき事由によりユーザーに現実に発生した直接かつ通常の損害（ただし、逸失利益は除きます。）に限られ、間接的な損害や特別な事情から生じた損害（損害の発生を予見し、又は、予見し得た場合を含みます。）については、責任を負わないものとします。また、いかなる場合も当社がユーザー個人に対して負う損害賠償の額は、５，０００円又は当該損害が発生した月の直前１か月にユーザーから受領した本サービスに係る利用料金の総額のいずれか高い方を上限とします。
                <br />
                ４．本規約に定めた当社の責任を減免する全ての条項については、当社に故意又は重過失が存する場合には適用しないものとします。また、本規約のいずれかの条項の一部が民法、消費者契約法その他の法令により無効、取消、差し止めとなった場合であっても、残部の条項は継続して完全に効力を有するものとします。
              </p>
              <p>
                第 16 条（保証の否認及び免責）
                <br />
                １．当社は、本サービス、本サービスを通じて提供されるコンテンツ、第三者コンテンツ、ユーザーコンテンツ、及び外部サービス等から得られる情報その他本サービスによりユーザーが取得し得る一切の情報が、ユーザーの特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性・完全性を有すること、本サービスの利用がユーザーに適用のある法令又は業界団体の内部規則等に適合すること、及び不具合が生じないことについて、何ら保証するものではありません。
                <br />
                ２．当社は、本サービスを通じて提供されるユーザーコンテンツ及び第三者コンテンツが適法に利用可能であること、YouTube、Twitter、Facebook
                その他当社以外が提供する提携サービス等の利用規約等を遵守していること、ユーザー及び第三者の権利を侵害しないこと等について、如何なる保証も行うものではありません。
                <br />
                ３．当社は、本アプリが全ての携帯端末に対応していることを保証するものではなく、また、仮に本サービスの利用開始時に対応していた場合でも、本サービスの利用に供する携帯端末の
                OS
                のバージョンアップ等に伴い本サービスの動作に不具合が生じる可能性があることにつき、ユーザーはあらかじめ了承するものとします。当社は、かかる不具合が生じた場合に当社が行うプログラムの修正等により当該不具合が解消されることを保証するものではありません。
                <br />
                ４．ユーザーは、AppStore、GooglePlay
                等のアプリストアの利用規約の変更等に伴い、本アプリ及び本サービスの一部又は全部の利用が制限される可能性があることをあらかじめ了承するものとします。
                <br />
                ５．当社は、コンテンツの認識の精度及び GPS
                の精度につき検証を重ねておりますが、ユーザーが本サービスを使用する環境や通信状況の変化その他の外部的事情により、精度が低下する場合があることにつき、ユーザーはあらかじめ了承するものとします。
                <br />
                ６．当社は、ユーザー情報及び端末情報等を、実績があると当社が判断したクラウド環境のもとで、安全性の高いネットワーク上に保存いたします。しかしながら、当社は、これらの完全な安全性、信頼性等を保証するものではなく、保存されたユーザー情報及び端末情報等その他の消失に起因して生じた損害につき、賠償する責任を一切負わないものとします。
                <br />
                ７．当社は、原則として、ユーザー間の通信や活動に関与しません。万一ユーザー間で紛争や問題が生じた場合、ユーザーは、直ちにその旨を当社に通知するとともに、自己の責任と費用においてこれを解決するものとし、当社はこれに一切関与せず、何ら責任を負わないものとします。
                <br />
                ８．本サービスに関し、ユーザーと提携パートナーその他の第三者との間で紛争が生じた場合、ユーザーは、直ちにその旨を当社に通知するとともに、自己の責任と費用においてこれを解決するものとし、当社はこれに一切関与せず、何ら責任を負わないものとします。
              </p>
              <p>
                第 17 条（規約改訂）
                <br />
                １．当社は、以下の場合には、いつでもサービス利用契約の内容の変更又は追加（以下「本規約の変更等」といいます。）をできるものとします。
                <br />
                （１）サービス利用契約の変更等が、ユーザーの一般の利益に適合する場合
                <br />
                （２）サービス利用契約の変更等が、契約をした目的に反せず、かつ、変更の必要性、変更後の内容の相当性等その他の変更に係る事情に照らして合理的なものである場合
                <br />
                （３）その他法令に基づき認められる場合
                <br />
                ２．当社は、サービス利用契約の変更等をする場合には、次条に定める方法により、当該変更等の７日前までに、ユーザーに当該変更内容を通知するものとし、当該変更内容の通知後、ユーザーが本サービスを利用した場合又は当社の定める期間内に利用終了の手続をとらなかった場合には、ユーザーは、サービス利用契約の変更等に同意したものとみなします。
              </p>
              <p>
                第 18 条（連絡・通知）
                <br />
                １．サービス利用契約の変更等に関する通知その他本サービスに関する当社からユーザーへの連絡は、当社ウェブサイト内の適宜の場所への掲示、電子メールの送信、又はプッシュ通知その他当社が適当と判断する方法により行うものとします。
                <br />
                ２．本サービスに関する問い合わせその他ユーザーから当社に対する連絡又は通知は、当社ウェブサイト内の適宜の場所に設置するお問い合わせフォームへの送信その他当社が指定する方法により行うものとします。
                <br />
                ３．当社は、ユーザーが登録したメールアドレスに、本サービスに関する広告・宣伝等のメールを配信することがあります。
              </p>
              <p>
                第 19 条（本規約上の地位の譲渡等）
                <br />
                １．ユーザーは、当社の書面による事前の承諾なく、サービス利用契約上の地位又は本規約に基づく権利義務の全部又は一部を、第三者に譲渡（合併、会社分割等による包括承継も含みます。以下本条において同様です。）し又は担保の目的に供することはできません。本項に反してサービス利用契約上の地位又は本規約に基づく権利義務の全部又は一部を第三者に譲渡し、又は担保の目的に供した場合、当社は何ら催告をせず、サービス利用契約を直ちに解除できるものとします。
                <br />
                ２．前項に反してサービス利用契約上の地位又は本規約に基づく権利義務の全部又は一部を第三者に譲渡し、又は担保の目的に供した場合であっても、ユーザーは、譲受人に対して、事前に前項の譲渡禁止特約の存在を通知しなければならないものとします。
                <br />
                ３．当社が本サービスにかかる事業を他社に譲渡した場合には、当該事業譲渡に伴いサービス利用契約上の地位、権利及び義務並びにユーザー情報その他の顧客情報を当該事業譲渡の譲受人に譲渡できるものとし、ユーザーは、かかる譲渡につき本項において予め同意したものとみなします。本項にいう事業譲渡には、当社が消滅会社又は分割会社となる合併又は会社分割等による包括承継を含むものとします。
              </p>
              <p>
                第 20 条（分離可能性）
                <br />
                本規約のいずれかの条項又はその一部が、消費者契約法その他の法令等により無効又は執行不能と判断された場合であっても、本規約の残りの規定、及びその一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有し、当社及びユーザーは、当該無効若しくは執行不能の条項又は部分を適法とし、執行力をもたせるために必要な範囲で修正し、当該無効若しくは執行不能な条項又は部分の趣旨並びに法律的及び経済的に同等の効果を確保できるように努めるものとします。
              </p>
              <p>
                第 21 条（準拠法及び合意管轄）
                <br />
                本規約の準拠法は日本法とし、本規約に起因し又は関連する一切の紛争については、訴額に応じて、東京簡易裁判所又は東京地方裁判所を第一審の専属的合意管轄裁判所とします。
              </p>
              <p>
                第 22 条（協議解決）
                <br />
                １．当社及びユーザーは、本規約に定めのない事項又は本規約の解釈に疑義が生じた場合には、互いに信義誠実の原則に従って協議の上速やかに解決を図るものとします。
                <br />
                ２．当社及びユーザーは、前項の協議を行うに際して相手方が要求する場合、当該協議を行う旨の書面又は電磁的記録による合意をしなければならないものとします。
              </p>
              <p>【2022 年 7 月 7 日 制定】</p>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default TermsPage;
